import React, { useEffect, useState } from "react";
import styles from "./managementStaff.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const ManagementStaff = () => {

  const [modal, setModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardInfo | null>(null);
  const { translate } = useLanguage();

  interface CardInfo {
    img: string;
    name: string;
    title: string;
    mail: string;
    description: string;
  }

  const cardData: CardInfo[] = [
    {
      img: images.mng1,
      name: "İbrahim AYDIN",
      title: translate('s2'),
      mail: "ibrahim.aydin@tein.com.tr",
      description: translate('bio1'),
    },
    {
      img: images.mng2,
      name: "Mustafa ÇÖDEK",
      title: translate('s3'),
      mail: "mustafa.codek@tein.com.tr",
      description: translate('bio2'),
    },
    {
      img: images.mng3,
      name: "Pınar YİĞİT",
      title: translate('s3'),
      mail: "pinar.yigit@tein.com.tr",
      description: translate('bio3'),
    },
    {
      img: images.mng4,
      name: "Esen SAKUÇ",
      title: translate('s3'),
      mail: "esen.sakuc@tein.com.tr",
      description: translate('bio4'),
    },
    {
      img: images.mng5,
      name: "Burcu PARLAK",
      title: translate('s3'),
      mail: "muhasebe@tein.com.tr",
      description: translate('bio5'),
    },
    {
      img: images.mng6,
      name: "Bekir SOLMAZGÜL",
      title: translate('s3'),
      mail: "",
      description: translate('bio6'),
    },
  ]

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [modal]);

  const openModal = (cardInfo: CardInfo) => {
    setModal(true);
    setSelectedCard(cardInfo)
  };

  const closeModal = () => {
    setModal(false);
  };

  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>{translate('s1')}</h1>
    </div>
    <div className={styles.cards}>
      {cardData.map((card, index) => (
        <div
          key={index}
          className={styles.card}
          onClick={() => openModal(card)}
        >
          <img src={card.img} alt={card.name} />
          <h1>{card.name}</h1>
          <span>{card.title}</span>
        </div>
      ))}
    </div>
    {modal &&
      <div className={styles.modal} onClick={closeModal}>
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <span className={styles.closeButton} onClick={closeModal}>&times;</span>
          {selectedCard && (
            <div className={styles.modalCont}>
              <div className={styles.modalImage}>
                <img src={selectedCard.img} alt={selectedCard.name} />
              </div>
              <div className={styles.modalText}>
                <div className={styles.modalInfo}>
                  <h1>{selectedCard.name}</h1>
                  <span>{selectedCard.title}</span>
                  <a href={`mailto:${selectedCard.mail}`} className={styles.mail}>
                    {selectedCard.mail}
                  </a>
                </div>
                <div className={styles.modalDetails}>
                  <p>{selectedCard.description}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    }
  </div>;
}

export default ManagementStaff;